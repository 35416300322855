import React, { useEffect, useState } from 'react';
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Modal, Backdrop, Fade, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import './Form.css';

import dustyLogo from '../images/dusty-logo.png';
import lineImage from '../images/linework.png';

import { v4 as uuidv4 } from 'uuid';

const chunkSize = 10 * 1024;

function Form() {

    const [dropzoneActive, setDropzoneActive] = useState(false);
    // const [files, setFiles] = useState([]);
    // const [currentFileIndex, setCurrentFileIndex] = useState(null);
    // const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
    // const [currentChunkIndex, setCurrentChunkIndex] = useState(null);

// const Form = () => {
    const classes = useStyles();
    const [ready, setReady] = useState(false);
    const [fileName, setFileName] = useState('default.json');

    const [jsonObject, setJsonObject] = useState(
        [
            {"name": "None", "dash_style": "0", "line_text":""},
            {"name": "-", "dash_style": "0", "line_text":""},
            {"name": "- -", "dash_style": "1", "line_text":""},
            {"name": ". .", "dash_style": "2", "line_text":""},
            {"name": "- . - .", "dash_style": "3", "line_text":""},
            // {"name": "- . . - . .", "dash_style": "4", "line_text":""},
            {"name": "- - . - - .", "dash_style": "5", "line_text":""},
            // {"name": "- - . . - - . .", "dash_style": "6", "line_text":""},
            {"name": "- - - . - - - .", "dash_style": "7", "line_text":""},
            {"name": "Soffit", "dash_style": "0", "line_text":"SOFFIT"},
            {"name": "OFFSET solid", "dash_style": "0", "line_text":"OFFSET"},
            {"name": "OFFSET dashed", "dash_style": "1", "line_text":"OFFSET"},
            {"name": "1' Offset Framing", "dash_style": "0", "line_text":"1' OFFSET FRAMING"},
            {"name": "2' Offset Framing", "dash_style": "0", "line_text":"2' OFFSET FRAMING"},
            {"name": "3' Offset Framing", "dash_style": "0", "line_text":"3' OFFSET FRAMING"},
            // {"name": "1' Offset Finish", "dash_style": "3", "line_text":"1' OFFSET FINISH"},
            // {"name": "2' Offset Finish", "dash_style": "3", "line_text":"2' OFFSET FINISH"},
            {"name": "Grid", "dash_style": "0", "line_text":"GRID"}
        ]
    )

    function handleDrop(e) {
        e.preventDefault();

        console.log(e)
        console.log(e.dataTransfer.files[0])
        readFile(e.dataTransfer.files[0])
        // setFiles([...files, ...e.dataTransfer.files]);
    }
    
    // // function readAndUploadCurrentChunk() {
    // //     const reader = new FileReader();
    // //     const file = files[currentFileIndex];
    // //     if (!file) {
    // //       return;
    // //     }
    // //     const from = currentChunkIndex * chunkSize;
    // //     const to = from + chunkSize;
    // //     const blob = file.slice(from, to);
    // //     reader.onload = e => uploadChunk(e);
    // //     reader.readAsDataURL(blob);
    // }
    
    //   function uploadChunk(readerEvent) {
    //     const file = files[currentFileIndex];
    //     const data = readerEvent.target.result;
    //     const params = new URLSearchParams();
    //     params.set('name', file.name);
    //     params.set('size', file.size);
    //     params.set('currentChunkIndex', currentChunkIndex);
    //     params.set('totalChunks', Math.ceil(file.size / chunkSize));
    //     const headers = {'Content-Type': 'application/octet-stream'};
    //     const url = 'http://localhost:4001/upload?'+params.toString();
    //     axios.post(url, data, {headers})
    //       .then(response => {
    //         const file = files[currentFileIndex];
    //         const filesize = files[currentFileIndex].size;
    //         const chunks = Math.ceil(filesize / chunkSize) - 1;
    //         const isLastChunk = currentChunkIndex === chunks;
    //         if (isLastChunk) {
    //           file.finalFilename = response.data.finalFilename;
    //           setLastUploadedFileIndex(currentFileIndex);
    //           setCurrentChunkIndex(null);
    //         } else {
    //           setCurrentChunkIndex(currentChunkIndex + 1);
    //         }
    //       });
    //   }
    
    //   useEffect(() => {
    //     if (lastUploadedFileIndex === null) {
    //       return;
    //     }
    //     const isLastFile = lastUploadedFileIndex === files.length - 1;
    //     const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    //     setCurrentFileIndex(nextFileIndex);
    //   }, [lastUploadedFileIndex]);
    
    //   useEffect(() => {
    //     if (files.length > 0) {
    //       if (currentFileIndex === null) {
    //         setCurrentFileIndex(
    //           lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
    //         );
    //       }
    //     }
    //   }, [files.length]);
    
    //   useEffect(() => {
    //     if (currentFileIndex !== null) {
    //       setCurrentChunkIndex(0);
    //     }
    //   }, [currentFileIndex]);
    
    //   useEffect(() => {
    //     if (currentChunkIndex !== null) {
    //       readAndUploadCurrentChunk();
    //     }
    //   }, [currentChunkIndex]);

    const [open, setOpen] = useState(false);
    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    const readFile = (theFile) => {
        console.log('HELLO', theFile)
        // console.log(e.target.files[0].name)
        // setFileName(e.target.files[0].name)
        let nameOfFile = theFile.name

        const fileReader = new FileReader();
        fileReader.readAsText(theFile, "UTF-8");
        fileReader.onload = e => {
            let string = e.target.result
            // console.log("e.target.result", string);

            let lines = string.split('\n');
            // console.log("lines", lines);

            let filtered = lines.filter(function (line) {
                return line.indexOf('#') !== 0;
            });
            // console.log("filtered", filtered);

            let formatted = JSON.parse(filtered.join('\n'))
            // console.log('formatted', formatted)
            let validDashStyles = [0, 1, 2, 3, 5, 7]

            // check if an array
            if (!Array.isArray(formatted)) {
                // console.log('it is not an array')
                alert('Object is not an array. Please upload a valid JSON file.')
            } else {
                // console.log('its an array')
                for (let i=0; i<formatted.length; i++) {
                    // check if the key names are valid
                    if (formatted[i]?.name === undefined || formatted[i]?.dash_style === undefined || formatted[i]?.line_text === undefined) {
                        // console.log('index of invalid key:', i)
                        alert(`Invalid object key names. Please include "name", "dash_style", and "line_text" for each item.`)
                        return
                    }
                    // check if dash styles are valid
                    if (!validDashStyles.includes(parseInt(formatted[i].dash_style))) {
                        // console.log('index of invalid dash style:', i)
                        alert(`Invalid dash styles. The "dash_style" key can only have a value of 0, 1, 2, 3, 5, or 7.`)
                        return
                    }
                }
                setFileName(nameOfFile)
                setJsonObject(formatted)
                // console.log('hi')
            }
        };
    }

    const handleNameChange = (event) => {
        // console.log(event.target.value)
        // console.log(event.target.name)

        jsonObject[event.target.name].name = event.target.value

        // let temp_state = [...jsonObject]
        // let temp_element = { ...temp_state[event.target.name]}
        // temp_element["name"] = event.target.value
        // temp_state[event.target.name] = temp_element;
        // setJsonObject(temp_state)

        // let newArr = [...jsonObject]
        // newArr[0] = event.target.value;
        // setJsonObject(newArr)
    }

    const handleStyleChange = (event) => {
        console.log(event.target)

        jsonObject[event.target.name].dash_style = JSON.stringify(event.target.value)
    };

    const handleTextChange = (event) => {
        // console.log(event.target.value)
        // console.log(event.target.name)

        jsonObject[event.target.name].line_text = event.target.value
    }

    const addItem = () => {
        let newItem = { "name": "", "dash_style": "0", "line_text":"" }

        console.log(newItem)

        setJsonObject(prevArray => [...prevArray, newItem])

        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            })
        }, 100)
    }

    const deleteItem = (name) => {
        console.log(name);

        let newArray = jsonObject
        let filtered = newArray.filter((item) => item.name !== name)

        // console.log(jsonObject.filter((item) => item.name !== name))
        setJsonObject(filtered)

        // setJsonObject(jsonObject => jsonObject.filter((item) => item.name !== name))
        // console.log(jsonObject.slice(index+1, jsonObject.length))
        // setJsonObject([...jsonObject.slice(0,index), ...jsonObject.slice(index+1)])
    }

    const hiddenFileInput = React.useRef(null);

    const handleCustomFileBtn = (event) => {
        hiddenFileInput.current.click();
    }

    return (
        <div>
            <div className={classes.bannerContainer}>
                <div className={classes.topTextContainer}>
                    <img className={classes.logo} src={dustyLogo} alt="dusty-logo"/>
                    <Typography className={classes.header} variant="h2">Line Style Selector</Typography>
                    <Typography className={classes.subheader} variant="h2">Edit the default json or upload your own template</Typography>
                </div>
            </div>
            <div className={classes.fileSection}>
                <button
                    className={classes.customFileInput}
                    onClick={handleCustomFileBtn}
                >
                    Choose file
                </button>
                <input
                    className={classes.fileInput}
                    type="file"
                    accept="application/JSON"
                    onChange={e => readFile(e.target.files[0])}
                    style={{display: 'none'}}
                    ref={hiddenFileInput}
                />
                { fileName && (
                    <Typography className={classes.fileNameText} style={{color: 'black'}}>{fileName}</Typography>
                )}
            </div>
            <div
                onDragOver={e => {e.preventDefault(); setDropzoneActive(true);}}
                onDragLeave={e => {e.preventDefault(); setDropzoneActive(false);}}
                onDrop={e => handleDrop(e)}
                className={"dropzone" + (dropzoneActive ? " active" : "")}
                style={{ width: '80%', maxWidth: '800px', margin: 'auto', marginTop: '20px', display: fileName == 'default.json' ? 'block' : 'none' }}
            >
                <p>Drop your files here</p>
                {/* <br></br> */}
                {/* <br></br> */}
            </div>
            {/* <div className="files">
                {files.map((file,fileIndex) => {
                    let progress = 0;
                    if (file.finalFilename) {
                        progress = 100;
                    } else {
                        const uploading = fileIndex === currentFileIndex;
                        const chunks = Math.ceil(file.size / chunkSize);
                        if (uploading) {
                            progress = Math.round(currentChunkIndex / chunks * 100);
                        } else {
                            progress = 0;
                        }
                    }
                    return (
                        <a className="file" target="_blank"
                        href={'http://localhost:4001/uploads/'+file.finalFilename}>
                        <div className="name">{file.name}</div>
                        <div className={"progress " + (progress === 100 ? 'done' : '')}
                            style={{width:progress+'%'}}>{progress}%</div>
                        </a>
                    );
                })}
            </div> */}
            <div className={classes.lineContainer}>
                {jsonObject && jsonObject.map((line, index) => (
                    <>
                    <div className={classes.lineItem} key={uuidv4()}>
                        {/* <p>{index}</p> */}
                        <SpecialTextField 
                            key={`${line.name}_name`}
                            className={classes.nameField}
                            InputLabelProps={{ shrink: true }}
                            label="Name"
                            // textAlign='left'
                            variant="outlined" 
                            size="small"
                            name={index}
                            defaultValue={line.name}
                            onChange={handleNameChange}
                        />
                        <TrashBtn className={classes.mobileDeleteBtn} onClick={() => deleteItem(line.name)} size="small">
                            <ClearIcon className={classes.deleteIcon}/>
                        </TrashBtn>
                        <br />
                        <div className={classes.fieldGroup}>
                            <SpecialFormControl variant="outlined" size="small" className={classes.formControl} key={`${line.name}_type`}>
                                <InputLabel id="demo-simple-select-outlined-label">Style</InputLabel>
                                <Select
                                    className={classes.selected}
                                    defaultValue={parseInt(jsonObject[index].dash_style)}
                                    name={index}
                                    onChange={handleStyleChange}
                                    label="style"
                                >
                                    <MenuItem value={0}>solid</MenuItem>
                                    <MenuItem value={1}>dash</MenuItem>
                                    <MenuItem value={2}>dot</MenuItem>
                                    <MenuItem value={3}>dash-dot</MenuItem>
                                    {/* <MenuItem value={4}>dash-dot-dot</MenuItem> */}
                                    <MenuItem value={5}>dash-dash-dot</MenuItem>
                                    {/* <MenuItem value={6}>dash-dash-dot-dot</MenuItem> */}
                                    <MenuItem value={7}>dash-dash-dash-dot</MenuItem>
                                </Select>
                            </SpecialFormControl>
                            <br />
                            <SpecialTextField 
                                className={classes.lineTextInput}
                                key={line.name}
                                InputLabelProps={{ shrink: true }}
                                label="Line text"
                                variant="outlined" 
                                size="small"
                                name={index}
                                defaultValue={line.line_text}
                                onChange={handleTextChange}
                            />
                        </div>
                        <TrashBtn className={classes.deleteBtn} onClick={() => deleteItem(line.name)} size="small">
                            <ClearIcon className={classes.deleteIcon}/>
                        </TrashBtn>
                        <br />
                    </div>
                    <hr className={classes.divider} />
                    </>
                ))}
            </div>

            <div>
                { jsonObject && (
                    <>
                        <div className={classes.addBtn} onClick={() => addItem()}>
                            <Typography className={classes.addText}>
                                Create New <AddCircleIcon className={classes.addIcon} />
                            </Typography>
                        </div>
                        
                        <div className={classes.downloadContainer}>
                            <a
                                className={classes.downloadLink}
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                    JSON.stringify(jsonObject)
                                )}`}
                                download="line_styles.json"
                            >
                                <DownloadBtn onClick={() => {
                                    setReady(!ready)
                                    handleOpenModal()
                                }}>
                                    <GetAppIcon className={classes.downloadIcon}/>Download
                                </DownloadBtn>
                            </a>
                            <br />
                            {/* <button onClick={handleOpenModal}>
                                instructions
                            </button> */}
                        </div>
                    </>
                )}
            </div>

            <Modal
                // aria-labelledby="transition-modal-title"
                // aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <Box sx={modalStyle}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <Typography id="transition-modal-title" variant="h6" component="h2" style={{ color: 'black'}}>
                        Thank you for using Dusty's Line Selector
                    </Typography>
                    <Typography className={classes.instructionHeader} sx={{ mt: 2 }}>
                        Instructions for configuration:
                    </Typography>
                    <Typography className={classes.instructionText}>
                        1. Right-click on the Dusty Controller Program
                    </Typography>
                    <Typography className={classes.instructionText}>
                        2. Select 'Open file location'
                    </Typography>
                    <Typography className={classes.instructionText}>
                        3. Open the 'config' folder
                    </Typography>
                    <Typography className={classes.instructionText}>
                        4. Replace the 'line_styles' JSON file with the file you have created
                    </Typography>
                    <Typography className={classes.instructionText}>
                        5. Launch or restart the Dusty Controller Program
                    </Typography>
                    <Button className={classes.closeModalButton} onClick={handleCloseModal}>back</Button>
                </Box>
                </Fade>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    bannerContainer: {
        backgroundColor: 'rgba(196, 196, 196, 1)',
        paddingBottom: '30px',
        backgroundImage: `url(${lineImage})`,
    },
    topTextContainer: {
        marginLeft: '10vw',
        marginRight: '10vw',
    },
    logo: {
        height: '70px',
        marginTop: '8vw'
    },
    header: {
        color: 'white',
        '@media (max-width:450px)': {
            fontSize: '14vw',
        },
    },
    subheader: {
        color: 'white',
        fontSize: '20px',
        '@media (max-width:450px)': {
            fontSize: '5vw',
        },
    },
    fileNameText: {
        // verticalAlign: 'middle',
        marginTop: '20px',
        marginLeft: '10px'
    },
    customFileInput: {
        marginTop: '15px',
        backgroundColor: '#f6862a',
        borderStyle: 'none',
        color: 'white',
        padding: '8px',
        borderRadius: '10px',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '15px',
        '&:hover': {
            cursor: 'pointer',
            // color: '#3d3d3d',
            backgroundColor: '#fa9f55'
        },
    },
    lineContainer: {
        marginLeft: '10vw',
        marginRight: '10vw',
        marginTop: '40px',
    },
    lineItem: {
        // backgroundColor: '#e6e6e6',
        marginTop: '20px',
        marginBottom: '20px',
        // marginBottom: '10px',
        // padding: '15px',
        display: 'flex',
        flexWrap: 'wrap',
        '@media (max-width:507px)': {
            // backgroundColor: 'black',
            marginTop: '5vw',
            marginBottom: '2vw',
        },
    },
    nameField: {
        display: 'flex',
        justifyContent: 'left',
        maxWidth: '250px'
    },
    fieldGroup: {
        display: 'flex',
        // marginTop: '6px',
        marginLeft: '2vw',
        // justifyContent: 'center',
        // verticalAlign: 'middle',
        '@media (max-width:710px)': {
            // backgroundColor: 'black',
            marginLeft: '0',
            marginRight: '50px',
            marginTop: '10px',
        },
    },
    formControl: {
        width: '150px'
    },
    lineTextInput: {
        marginLeft: '2vw',
        // marginRight: '2vw'
    },
    selected: {
        textAlign: 'left'
    },
    divider: {
        borderColor: 'rgba(255, 255, 255, 0.5)',
        borderWidth: '1px',
        // backgroundColor: 'blue',
    },
    mobileDeleteBtn: {
        display: 'flex',
        justifyContent: 'left',
        // float: 'left',
        // width: '10px'
        // marginTop: '10px',

        '@media (min-width:711px)': {
            // backgroundColor: 'black',
            display: 'none'
        },
    },
    deleteBtn: {
        display: 'flex',
        justifyContent: 'left',
        // float: 'left',
        // width: '10px'
        // marginTop: '10px',

        '@media (max-width:750px)': {
            // backgroundColor: 'black',
            display: 'none'
        },
    },
    deleteIcon: {
        margin: 'auto',
        color: '#f6862a',
        // textAlign: 'left',
        // float: 'left',
    },
    addBtn: {
        marginTop: '10px',
        marginBottom: '25px',
        borderColor: 'gray',
        borderWidth: '1px',
        // backgroundColor: 'gray',
        width: '130px',
        margin: 'auto',
        '&:hover': {
            cursor: 'pointer',
            color: '#3d3d3d',
            "& $addIcon": {
                color: "#faa35c"
            },
            "& $addText": {
                color: "#faa35c"
            },
        },
    },
    addText: {
        fontSize: '16px',
        color: '#f6862a',
        // borderWidth: '1px',
        // borderColor: '2px solid gray',
        // '&:hover': {
        //     color: '#fc9c4e',
        // },
    },
    addIcon: {
        verticalAlign: 'middle',
        marginBottom: '3px',
        fontSize: '20px',
        color: '#f6862a',
        // '&:hover': {
        //     color: '#fc9c4e',
        // },
    },
    downloadContainer: {
        // marginTop: '30px',
        marginBottom: '100px',
    },
    downloadLink: {
        textDecoration: 'none',
    },
    downloadIcon: {
        marginRight: '5px',
        fontSize: '20px'
    },
    checkIcon: {
        verticalAlign: 'middle',
        marginBottom: '6px',
        // textAlign: 'center',
        margin: 'auto',
        fontSize: '35px',
        color: '#3cb01c',
        // color: '#1891c4',
    },
    instructionHeader: {
        marginTop: '10px',
        marginBottom: '5px',
        color: '#595959',
    },
    instructionText: {
        fontSize: '13px',
        color: '#595959',
    },
    closeModalButton: {
        marginTop: '15px',
        backgroundColor: '#999',
        color: 'white',
        padding: '2px',
        fontSize: '12px',
        cursor: 'pointer',
        '&:hover': {
            // color: '#3d3d3d',
            backgroundColor: '#777'
        },
    },
    fileSection: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
    },
}))

const SpecialTextField = withStyles({
    root: {
        '& label': {
            // color: 'black',
            // backgroundColor: 'white',
            // padding: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            // color: 'black',
            // backgroundColor: 'orange',
            // padding: '3px',
            color: 'gray',
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderColor: 'white',
                // borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'orange',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
                borderWidth: '2px',
            },
        },
    },
})(TextField);

const SpecialFormControl = withStyles({
    root: {
        '& label': {
            // color: 'black',
            // backgroundColor: 'white',
            // padding: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            // color: 'black',
            // backgroundColor: 'orange',
            // padding: '3px',
            color: 'gray',
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderColor: 'white',
                // borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'orange',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
                borderWidth: '2px',
            },
        },
    },
})(FormControl);

const TrashBtn = withStyles((theme) => ({
    root: {
    //   backgroundColor: '#f6862a',
      marginLeft: 'auto',
      marginBottom: '2px',
      marginRight: 0,
      maxWidth: '30px',
      minWidth: '30px',
    //   justifyContent: 'flex-end',
    //   textAlign: 'right',
      '&:hover': {
        // backgroundColor: '#e67c27',
      },
      '@media (max-width:750px)': {
        // backgroundColor: 'black',
        // maxWidth: '0px',
        // minWidth: '0px',
      },
    },
}))(Button);

const DownloadBtn = withStyles((theme) => ({
    root: {
      backgroundColor: '#f6862a',
      color: "white",
      borderColor: 'gray',
      borderWidth: '1px',
      '&:hover': {
        backgroundColor: '#e67c27',
      },
    },
}))(Button);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '75%'
    }
}

export default Form